import { setupDevtoolsPlugin } from "@vue/devtools-api";
import { ApplicationStore, Store } from "kita";
import { capitalize } from "vue";
import { AsyncModel } from "../models";

const INSPECTOR_ID = "data-store";

const debounce = (callback: () => void, delay = 300) => {
  let timeoutId: string | number | NodeJS.Timeout | undefined;

  return (...args: []) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => callback.apply(this, args), delay);
  };
};

export default function setupDevtools(app: any) {
  setupDevtoolsPlugin(
    {
      id: "vandal-data-store",
      label: "Vandal Data Store",
      app,
    },
    (api) => {
      const dataStore = app.config.globalProperties.store as ApplicationStore;

      // @ts-expect-error
      delete dataStore["client"];

      let allRecords = Object.values(dataStore).reduce((acc, s) => {
        try {
          return [...acc, ...s.records];
        } catch (e) {
          console.error(e);
          return acc;
        }
      }, []);

      const filterState = {
        showNew: true,
        showPersisted: true,
      };

      // Add to Vue Devtools Panel
      api.addInspector({
        id: INSPECTOR_ID,
        label: "Data Store",
        icon: "storage",
        treeFilterPlaceholder: "Search records",
        actions: [
          {
            icon: "fiber_new",
            tooltip: "Toggle Show/Hide New Records",
            action: debounce(() => {
              filterState.showNew = !filterState.showNew;
              api.sendInspectorTree(INSPECTOR_ID);
            }, 50),
          },
          {
            icon: "save",
            tooltip: "Toggle Show/Hide Persisted Records",
            action: debounce(() => {
              filterState.showPersisted = !filterState.showPersisted;
              api.sendInspectorTree(INSPECTOR_ID);
            }, 50),
          },
        ],
      });

      // Build UI
      api.on.getInspectorTree((payload /** context */) => {
        if (payload.inspectorId !== INSPECTOR_ID) return;

        payload.rootNodes = Object.entries(dataStore)
          .map(([storeName, storeInstance]) => {
            try {
              return createStoreTree({
                storeName,
                storeInstance,
                filter: payload.filter,
                filterState,
              });
            } catch (e) {
              console.error(`Failure attempting to create store tree for ${storeName}`, e);
              return null;
            }
          })
          .filter((x) => !!x);
      });

      // Show the selected record
      api.on.getInspectorState((payload) => {
        if (payload.inspectorId !== INSPECTOR_ID) return;

        const selectedRecord = allRecords.find(({ id }: AsyncModel) => id === payload.nodeId);

        if (!selectedRecord) {
          payload.state = {
            // TODO: owen fix this
            // @ts-expect-error
            Store: dataStore.getStore(payload.nodeId),
          };
        } else {
          const formattedRecord = {
            Record: selectedRecord,
          };

          payload.state = formattedRecord;
        }
      });

      // refresh the UI every 2 seconds
      setInterval(() => {
        api.sendInspectorTree(INSPECTOR_ID);
        allRecords = Object.values(dataStore).reduce((acc, s) => {
          try {
            return [...acc, ...s.records];
          } catch (e) {
            console.error(e);
            return acc;
          }
        }, []);
      }, 2000);
    },
  );
}

function createStoreTree({
  storeName,
  storeInstance,
  filter,
  filterState,
}: {
  storeName: string;
  storeInstance: Store<any>;
  filter: string;
  filterState: { showNew: boolean; showPersisted: boolean };
}) {
  const searchFilterFn = filter
    ? (record: AsyncModel) => record.toString().toLowerCase().includes(filter.toLowerCase())
    : () => true;

  const booleanFilterFn = (record: AsyncModel) => {
    if (filterState.showNew && record.isNew) return true;
    if (filterState.showPersisted && !record.isNew) return true;

    return false;
  };

  return {
    id: storeName,
    label: `${capitalize(storeName)} (${storeInstance.records.length})`,
    children: storeInstance.records
      .filter(booleanFilterFn)
      .filter(searchFilterFn)
      .map((record) => {
        return {
          id: record.id,
          label: record.toString(),
          tags: [
            {
              label: record.isNew ? "New" : "Persisted",
              textColor: 0xffffff,
              backgroundColor: record.isNew ? 0x4caf50 : 0x2196f3,
            },
          ],
        };
      }),
  };
}
