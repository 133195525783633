import { VandalStore } from "@/stores/application-store";
import { AsyncModel } from "kita";

export default class VandalModel extends AsyncModel {
  declare createdAt: Date;
  declare updatedAt: Date;

  get stores() {
    return super.stores as VandalStore;
  }
}
