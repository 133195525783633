import { Model } from "kita";

export default class TagStatsModel extends Model {
  type = "tag_stats";

  declare tagsPlanted: number;
  declare placeTags: number;
  declare totalVotes: number;
  declare placesCreated: number;
}
