import TagModel from "@/models/TagModel";
import VandalModel from "@/models/VandalModel";
import { connectToStore } from "kita";

@connectToStore
export default class PlaceTagModel extends VandalModel {
  static readonly id = "placeTags";

  declare isLiked: boolean;
  declare myVote: number;
  declare tag: TagModel | string;
  declare tagName: string;
  declare votes: number;
  declare createdBy: string;
  declare isLocked: boolean;
  declare placeId: string;

  get place() {
    return this.stores.places.findRecord(this.placeId);
  }

  toString() {
    return `${this.placeId}: ${this.tag instanceof Object ? this.tag.name : this.tag}`;
  }
}
