import type TagModel from "@/models/TagModel";
import VandalModel from "@/models/VandalModel";
import { connectToStore } from "kita";

export type Coordinates = {
  coords: Array<number>;
  latitude: number;
  longitude: number;
};

@connectToStore
export default class PlaceModel extends VandalModel {
  static readonly id = "places";

  declare name: string;
  declare osmId: number;
  declare distanceInMeters: number;
  declare location: Coordinates;
  declare totalTags: number;
  declare createdBy: string;
  declare tags: Array<TagModel>;
  declare topTags: Array<TagModel>;

  toString() {
    return this.name;
  }
}
