interface BasePointType {}

interface LongPointType extends BasePointType {
  latitude: number | string;
  longitude: number | string;
}

interface ShortPointType extends BasePointType {
  lat: number | string;
  long: number | string;
}

type PointType = Partial<LongPointType & ShortPointType>;

//  TODO: figure out how to better tell typescript that either lat/long or
//  latitude/longitude are OK as params
export default class Point {
  public latitude: number;
  public longitude: number;

  constructor(point: PointType) {
    const lat = point?.latitude ?? point?.lat;
    const long = point?.longitude ?? point?.long;

    if (!lat || !long) {
      throw new Error("Invalid point");
    }

    this.latitude = +lat;
    this.longitude = +long;
  }

  public static initFromLatLongString(pointString: string) {
    const { 0: lat, 1: long } = pointString.split(",");
    return new this({ lat, long });
  }

  public static initFromLongLatString(pointString: string) {
    const { 0: long, 1: lat } = pointString.split(",");
    return new this({ lat, long });
  }

  public toLatLongString() {
    return `${this.latitude},${this.longitude}`;
  }

  public toLongLatString() {
    return `${this.longitude},${this.latitude}`;
  }

  public static initFromGisPoint(gisPoint: string) {
    // 'POINT(-122.533677 37.89515)' <- GIS point string example
    const [long, lat] = gisPoint.replace("POINT(", "").replace(")", "").split(" ");
    return new this({ lat, long });
  }
}
