import TagStatsModel from "@/models/TagStatsModel";
import UserModel from "@/models/UserModel";
import { getSelf as fetchSelf } from "@/services/api/authService";
import VandalModelStore from "@/stores/vandal-model-store";

export default class UserStore extends VandalModelStore<UserModel> {
  static readonly id = "users";
  _activeUser = ref({} as UserModel);

  get activeUser() {
    return this._activeUser.value;
  }

  set activeUser(value) {
    this._activeUser.value = value;
  }

  async getSelf() {
    if (this.activeUser && Object.keys(this.activeUser).length > 0) {
      return this.activeUser;
    }

    const self = await fetchSelf();
    // @ts-expect-error TODO: fix later
    this.activeUser = UserModel.create(self.data);

    return this.activeUser;
  }

  async getStats() {
    if (!this.activeUser) return;

    const res = await this.client.get("/profile/me/stats/");

    this.activeUser.tagStats = TagStatsModel.create(res.data);
  }
}
