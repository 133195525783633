import PlaceModel from "@/models/PlaceModel";
import type PlaceTagModel from "@/models/PlaceTagModel";
import Point from "@/models/logical/Point";
import VandalModelStore from "@/stores/vandal-model-store";
import { showPrivacyError } from "@/utils/location-settings-error";
import { objectUnion } from "@/utils/not-lodash";
import Bugsnag from "@bugsnag/js";
import { reactive } from "kita";

interface FindRecordsParams {
  tagId?: string;
  boundaries?: Record<string, unknown>;
  limit?: number;
}

class PlaceStore extends VandalModelStore<PlaceModel> {
  static readonly id = "places";
  retries = 0;

  @reactive([])
  declare myPlaces: PlaceModel[];

  @reactive([])
  declare searchResults: PlaceModel[];

  @reactive({})
  declare placeTags: Record<string, PlaceTagModel[]>;

  @reactive({})
  declare tagVotes: Record<string, Record<string, unknown>[]>;

  protected reset() {
    super.reset();

    this.retries = 0;
  }

  getPlaceById(placeId: string): PlaceModel | undefined {
    return this.records.find(({ id }) => id === placeId);
  }

  public getPlaceTags(placeId: string): PlaceTagModel[] {
    return this.placeTags[placeId];
  }

  public getPlaceTagsWithVoteInfo(placeId: string): PlaceTagModel[] {
    return this.placeTags[placeId];
  }

  override async findRecord(id: string): Promise<PlaceModel | undefined> {
    try {
      const findPlaceRes = this._findRecordWithPoint(id);
      const existingPlace = this.records.find((record) => record.id === id);

      if (existingPlace) {
        return existingPlace;
      } else {
        const newPlace = await findPlaceRes;

        return newPlace;
      }
    } catch (e: any) {
      Bugsnag.notify(e);
      console.log(e);
    }
  }

  async _findRecordWithPoint(id: string) {
    const point = await this.getCurrentPoint();
    let res;

    if (point) {
      res = await this.client.get(`/${this.APIUrl}/${id}/`, {
        params: {
          lat: point.latitude,
          long: point.longitude,
        },
      });
    } else {
      res = await this.client.get(`/${this.APIUrl}/${id}/`);
    }

    return PlaceModel.create(res.data);
  }

  override async findRecords(
    params: FindRecordsParams = {},
    replaceStore = false,
  ): Promise<PlaceModel[] | undefined> {
    try {
      const point = await this.getCurrentPoint();

      if (!point) {
        console.error("Could not find GPS coordinates");
        return;
      }

      const { data } = await this.client.get(`/${this.APIUrl}/`, {
        params: {
          page: replaceStore ? 1 : this.nextPage,
          lat: point.latitude,
          long: point.longitude,
          ...params,
        },
      });

      const finalData = params.boundaries ? data : data.data;

      if (replaceStore) this.reset();

      finalData?.forEach((params: Record<string, unknown>) => PlaceModel.create(params));

      this.nextPage += 1;

      return this.records;
    } catch (e: any) {
      Bugsnag.notify(e);
      console.log(e);

      if (e instanceof GeolocationPositionError) {
        if (replaceStore) {
          showPrivacyError(false);

          throw e;
        }

        return;
      }

      if (this.retries === 15) {
        return;
      }

      setTimeout(() => this.findRecords(params, replaceStore), 300);

      this.retries += 1;
    }
  }

  override _deleteRecord(place: PlaceModel): Promise<void> {
    return this.client.post("/places/request_deletion", { id: place.id });
  }

  public async fetchTagsForPlace(id: string) {
    try {
      const placeTags = await this.stores.placeTags.findRecords({ placeId: id });
      this.placeTags[id] = placeTags;
      this.sortPlaceTags(id);
    } catch (e: any) {
      Bugsnag.notify(e);
      console.error(e);
    }
  }

  public async findPlacesWithTag(tagId: string, page: number) {
    try {
      const point = await this.getCurrentPoint();

      if (!point) {
        return console.error("Could not fetch current location");
      }

      const res = await this.client.get(`/tags/${tagId}/places`, {
        params: {
          lat: point.latitude,
          long: point.longitude,
          page,
        },
      });

      const placeRecords = res.data.data.map((placeData: object) => PlaceModel.create(placeData));

      return { data: placeRecords, meta: res.data.meta };
    } catch (e: any) {
      Bugsnag.notify(e);
    }
  }

  public sortPlaceTags(id: string) {
    this.placeTags[id] =
      this.placeTags[id].sort((a: PlaceTagModel, b: PlaceTagModel) =>
        a.votes > b.votes ? -1 : 1,
      ) ?? [];
  }

  public async search(query: string, lat: string | number, long: string | number) {
    try {
      const res = await this.client.get("places/search/", {
        params: { q: query, lat, long },
      });

      this.searchResults = res.data;
    } catch (e: any) {
      Bugsnag.notify(e);
      console.error(e);
    }
  }

  public async deleteTag(placeId: string, placeTagId: string) {
    try {
      const placeTag = this.placeTags[placeId].find((pt: PlaceTagModel) => pt.id === placeTagId);

      if (!placeTag) return;

      await placeTag.delete();

      this.placeTags[placeId] = this.placeTags[placeId].filter(
        (placeTag: PlaceTagModel) => placeTag.id !== placeTagId,
      );
    } catch (e: any) {
      Bugsnag.notify(e);
      console.error(e);

      return e;
    }
  }

  public async getVotes(placeId: string) {
    try {
      const tagVotes = await this.stores.placeTags.getVotes(placeId);

      this.tagVotes[placeId] = tagVotes;
    } catch (e: any) {
      Bugsnag.notify(e);
      console.error(e);

      return e;
    }
  }

  public async voteTag(placeTag: PlaceTagModel, vote: number) {
    try {
      const userStore = this.stores.users;
      const currentUser = userStore.activeUser;
      const placeId = placeTag.placeId;
      const existingVote = this.tagVotes[placeId].find(
        (tagVote: Record<string, unknown>) =>
          tagVote.placeTagId === placeTag.id && tagVote.userId === currentUser.id,
      );

      const tagVote = await this.stores.placeTags.votePlaceTag(placeTag.id, vote);

      if (!existingVote) {
        this.tagVotes[placeId].push(tagVote);
      } else {
        existingVote.vote = vote;
      }

      this.updateExistingPlaceTag(placeTag);
    } catch (e: any) {
      Bugsnag.notify(e);
      console.error(e);

      return e;
    }
  }

  public async createPlaceTags(placeId: string, tagIds: string[]) {
    const newPlaceTags = await this.stores.placeTags.bulkCreate(placeId, tagIds);

    this.placeTags[placeId] = objectUnion(this.placeTags[placeId], newPlaceTags, "id");
  }

  public async findNearbyPlaces(query: string, lat = 0, long = 0, useBoundingBox = false) {
    let point;

    try {
      point = (await this.getCurrentPoint()) as Point;
    } catch (e) {
      point = new Point({ lat, long });
    }

    const { data } = await this.client.get("places/get_nearby_locations/", {
      params: {
        q: query,
        lat: point.latitude,
        long: point.longitude,
        use_bounding_box: useBoundingBox,
      },
    });

    return data;
  }

  public async findNewLocations(query: string) {
    const res = await this.client.get("places/search_for_new_locations/", {
      params: { query },
    });

    return res.data;
  }

  private async updateExistingPlaceTag(placeTag: PlaceTagModel) {
    const updatedPlaceTag = await this.stores.placeTags.findRecord(placeTag.id);

    Object.assign(placeTag, updatedPlaceTag);
  }

  private async getCurrentPoint() {
    const locationStore = this.stores.locations;
    const timer = (ms: number) => new Promise((res) => setTimeout(res, ms));
    let attempts = 0;

    while (locationStore.currentLocation.latitude === Infinity) {
      if (attempts > 50) break;

      attempts += 1;
      await timer(200);
    }

    if (locationStore.currentLocation.latitude === Infinity) {
      console.error("Unable to retrieve GPS coordinates");
      Bugsnag.notify("Unable to retrieve GPS coordinates on tag page");

      return null;
    }

    return new Point({
      lat: locationStore.currentLocation.latitude,
      long: locationStore.currentLocation.longitude,
    });
  }
}

export default PlaceStore;
