import UserModel from "@/models/UserModel";
import VandalModel from "@/models/VandalModel";
import { connectToStore } from "kita";

@connectToStore
export default class TagModel extends VandalModel {
  static readonly id = "tags";

  declare readonly name: string;
  declare readonly createdBy: UserModel;

  toString() {
    return this.name;
  }
}
