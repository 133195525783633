import { NotificationDeviceModel } from "@/models";
import stores from "@/stores/application-store";
import { PreferenceKeys } from "@/types/preferences";
import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";
import type {
  ActionPerformed,
  PushNotificationSchema,
  RegistrationError,
  Token,
} from "@capacitor/push-notifications";
import { PushNotifications } from "@capacitor/push-notifications";

export default class PushNotificationService {
  static async previouslyRequestedPermission() {
    const didRequestNotifications = await Preferences.get({
      key: PreferenceKeys.DidRequestNotifications,
    });

    return didRequestNotifications.value === "true";
  }

  static async canRequestPermission() {
    if (Capacitor.getPlatform() === "web") return false;

    const permission = await PushNotifications.checkPermissions();

    return permission.receive.startsWith("prompt");
  }

  static async silentlySyncToken() {
    if (Capacitor.getPlatform() === "web") return;
    const permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === "granted") {
      await this.register();
    }
  }

  static async register() {
    if (Capacitor.getPlatform() === "web") return;

    Preferences.set({ key: PreferenceKeys.DidRequestNotifications, value: "true" });

    let permStatus = await PushNotifications.checkPermissions();
    // PermissionState = 'prompt' | 'prompt-with-rationale' | 'granted' | 'denied';

    if (permStatus.receive.startsWith("prompt")) {
      // prompts user and will result in an event sent to
      // registrationCallback or registrationErrorCallback
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== "granted") {
      throw new Error("User denied permissions!");
    }

    await PushNotifications.register();
  }

  static async registrationEventHandler(token: Token) {
    Preferences.set({ key: PreferenceKeys.DidRequestNotifications, value: "true" });

    const userStore = stores.users;
    const user = await userStore.getSelf();

    const platform = Capacitor.getPlatform() === "ios" ? "apns" : "fcm";

    const notificationDevices = await stores.notificationDevices.findRecords();

    if (notificationDevices.find((record) => record.token === token.value)) return;

    const notificationDevice = NotificationDeviceModel.create({
      token: token.value.trim(),
      userId: user.id,
      platform,
    });

    return notificationDevice.save();
  }

  static registrationErrorEventHandler(err: RegistrationError) {
    console.error("Registration error: ", err.error);
  }

  static pushNotificationReceivedEventHandler(notification: PushNotificationSchema) {
    console.log("Push notification received: ", notification);
  }

  static pushNotificationActionPerformedEventHandler(notification: ActionPerformed) {
    console.log(
      "Push notification action performed",
      notification.actionId,
      notification.inputValue,
    );
  }
}

export function registerListeners() {
  if (Capacitor.getPlatform() === "web") return;

  return Promise.all([
    PushNotifications.addListener("registration", PushNotificationService.registrationEventHandler),
    PushNotifications.addListener(
      "registrationError",
      PushNotificationService.registrationErrorEventHandler,
    ),
    PushNotifications.addListener(
      "pushNotificationReceived",
      PushNotificationService.pushNotificationReceivedEventHandler,
    ),
    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      PushNotificationService.pushNotificationActionPerformedEventHandler,
    ),
  ]);
}
