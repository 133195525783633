import { AxiosInstance } from "axios";
import { Ref, ref } from "vue";
import ModelStoreRegistry from "../model-store-registry";
import { AbstractModel, AsyncModel, Model } from "../models";

const underscore = (s: string) => {
  return s
    .split(/\.?(?=[A-Z])/)
    .join("_")
    .toLowerCase();
};

interface ConstructorArgs {
  APIUrl?: string;
}

export abstract class AbstractStore<T extends AbstractModel> {
  static readonly id: string;

  declare protected _records: Ref<T[]>;

  public get records() {
    return this._records.value;
  }

  public set records(value) {
    this._records.value = value;
  }

  protected abstract reset(): void;

  abstract findRecord(id: string): T | undefined;

  abstract _pushRecord(record: T): void;

  abstract _deleteRecord(record: T): void;

  abstract _updateRecord(record: T): T;
}

class Store<T extends Model> extends AbstractStore<T> {
  // eslint-disable-next-line
  constructor(..._args: any[]) {
    super();

    this._records = ref([]);
  }

  public findRecord(id: string) {
    return this.records.find((r: T) => r.id === id);
  }

  public _pushRecord(record: T) {
    const findFn = ({ id }: T) => id === record.id;
    const existingIndex = this.records.findIndex(findFn);

    if (existingIndex >= 0) {
      this.records[existingIndex] = record;
    } else {
      this.records.push(record);
    }

    return record;
  }

  public _removeRecord(record: T) {
    this.records = this.records.filter((r) => r.id !== record.id);
  }

  public _updateRecord(record: T) {
    return this._pushRecord(record);
  }

  public _deleteRecord(record: T) {
    this.records = this.records.filter((r) => r.id !== record.id);
  }

  protected reset() {
    this._records.value = [];
  }
}

class AsyncStore<T extends AsyncModel> {
  static readonly id: string;

  protected readonly _records: Ref<T[]>;
  protected readonly client: AxiosInstance;
  protected page = 1;
  protected nextPage = 1;
  protected APIUrl: string;

  constructor(client: AxiosInstance, args: ConstructorArgs) {
    this._records = ref([]);
    this.client = client;

    if (args.APIUrl) {
      this.APIUrl = args.APIUrl;
    } else {
      // @ts-expect-error
      this.APIUrl = underscore(this.constructor.id);
    }
  }

  public get records() {
    return this._records.value;
  }

  public set records(value) {
    this._records.value = value;
  }

  public get stores() {
    return ModelStoreRegistry.allStores();
  }

  private get modelType(): typeof AsyncModel {
    // @ts-expect-error
    return ModelStoreRegistry.getModel(this.constructor.id);
  }

  protected reset() {
    this._records.value = [];
    this.nextPage = 0;
    this.page = 0;
  }

  public async findRecord(
    id: string,
    params: Record<string, unknown> = {},
  ): Promise<T | undefined> {
    const findRecordRes = this._findRecord(id, params);
    const existingRecord = this.records.find((r: T) => r.id === id);

    if (existingRecord) {
      return existingRecord;
    } else {
      const newRecord = await findRecordRes;

      return newRecord;
    }
  }

  private async _findRecord(id: string, params: Record<string, unknown> = {}): Promise<T> {
    const res = await this.client.get(`/${this.APIUrl}/${id}/`, { params });

    return this.modelType.create(res.data) as T;
  }

  public async findRecords(
    params: Record<string, unknown> = {},
    replaceStore = false,
  ): Promise<T[]> {
    const res = await this.client.get(`/${this.APIUrl}/`, { params });
    const recordsJSON = res.data.meta ? res.data.data : res.data;

    if (replaceStore) this.reset();

    const newRecords = recordsJSON.map((json: object) => this.modelType.create(json)) as T[];

    return newRecords;
  }

  public async _updateRecord(record: T): Promise<T> {
    const res = await this.client.put(`/${this.APIUrl}/${record.id}/`, record.serialize());
    const updatedRecord = Object.assign(record, res.data);

    return updatedRecord;
  }

  public async _createRecord(record: T): Promise<T> {
    const res = await this.client.post(`/${this.APIUrl}/`, record.serialize());
    const newRecord = this._pushRecord(res.data);

    return newRecord;
  }

  public async _deleteRecord(record: T) {
    await this.client.delete(`/${this.APIUrl}/${record?.id}/`);

    this._removeRecord(record);
  }

  public _removeRecord(record: T) {
    this.records = this.records.filter((r) => r.id !== record.id);
  }

  public _pushRecord(record: T) {
    const findFn = ({ id }: T) => id === record.id;
    const existingIndex = this.records.findIndex(findFn);

    if (existingIndex >= 0) {
      this.records[existingIndex] = record;
    } else {
      this.records.push(record);
    }

    return record;
  }
}

export { AsyncStore, Store };
