import VandalModel from "@/models/VandalModel";
import { connectToStore } from "kita";

@connectToStore
export default class NotificationDeviceModel extends VandalModel {
  static readonly id = "notificationDevices";

  declare token: string;
  declare platform: string;
  declare isActive: boolean;
  declare userId: string;
}
