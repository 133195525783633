<script lang="ts" setup>
  import { logo } from "@/assets/img";
  import router from "@/router";
  import { requestPasswordReset, resetPassword } from "@/services/api/authService";
  import toast from "@/services/toastService";
  import Bugsnag from "@bugsnag/js";
  import { arrowBack } from "ionicons/icons";
  import { useRoute } from "vue-router";

  const route = useRoute();
  const title = "Request Reset Password";

  let email = ref(route.query.email || "");
  let isLoading = ref(false);

  let isConfirming = ref(false);

  let newPassword = ref("");
  let newPasswordConfirm = ref("");
  let token = ref("");

  const buttonText = computed(() =>
    isConfirming.value ? "reset password" : "request new password ",
  );

  function formHandler() {
    if (isConfirming.value) {
      sendResetPassword();
    } else {
      sendRequestPasswordReset();
    }
  }

  const sendRequestPasswordReset = async () => {
    isLoading.value = true;

    try {
      const res = await requestPasswordReset(email.value as string);
      toast.success("Reset Email Sent");
      if (res.data.pendingPasswordReset) {
        isConfirming.value = true;
      }
    } catch (e: any) {
      Bugsnag.notify(e);
      toast.error("Network Error - Try Again");
    } finally {
      isLoading.value = false;
    }
  };

  const sendResetPassword = async () => {
    isLoading.value = true;

    try {
      await resetPassword(
        email.value as string,
        token.value,
        newPassword.value,
        newPasswordConfirm.value,
      );
      toast.success("password reset!");
      router.push("/");
    } catch (e: any) {
      Bugsnag.notify(e);
      toast.error("Error Sending Reset");
    } finally {
      isLoading.value = false;
    }
  };
</script>

<template>
  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonTitle>{{ title }}</IonTitle>
        <IonButtons slot="start">
          <IonBackButton v-haptics text="" default-href="/login" :icon="arrowBack"></IonBackButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonContent>
      <ImageWithTitle :image="logo" title="# marks the spot" />

      <form class="ion-padding" @submit.prevent="formHandler" @keyup.enter="formHandler">
        <IonList>
          <IonItem>
            <IonInput
              v-model="email"
              placeholder="you@example.com"
              inputmode="email"
              type="email"
              autocomplete="email"
              autocapitalize="off"
              name="email"
            ></IonInput>
          </IonItem>
          <IonItem v-if="isConfirming">
            <IonInput v-model="token" type="number" placeholder="token"></IonInput>
          </IonItem>
          <IonItem v-if="isConfirming">
            <IonInput v-model="newPassword" type="password" placeholder="password"></IonInput>
          </IonItem>
          <IonItem v-if="isConfirming">
            <IonInput
              v-model="newPasswordConfirm"
              type="password"
              placeholder="confirm password"
            ></IonInput>
          </IonItem>
        </IonList>
        <IonButton type="submit" expand="block">
          {{ buttonText }}
          <IonSpinner v-if="isLoading" name="crescent" class="ion-margin-start" />
        </IonButton>
      </form>
    </IonContent>
  </IonPage>
</template>
