const debounce = (callback: () => void, delay = 300) => {
  let timeoutId: string | number | NodeJS.Timeout | undefined;

  return (...args: []) => {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => callback.apply(this, args), delay);
  };
};

const mapBy = (list: Array<any>, key: any) => {
  return list.map((elem) => elem?.[key]);
};

const uniq = (list: Array<any>) => {
  return [...new Set(list)];
};

const union = (arr1: Array<any>, arr2: Array<any>) => {
  const _set = new Set(arr1);

  arr2.forEach((elem) => _set.add(elem));

  return [..._set];
};

const objectUnion = (arr1: Array<any>, arr2: Array<any>, uniqueByField: string) => {
  const _union = arr1;

  arr2.forEach((elem: any) => {
    if (_union.find((e: any) => e[uniqueByField] === elem[uniqueByField])) return;

    _union.push(elem);
  });

  return _union;
};

const sortBy = (objList: Array<any>, sortField: string) => {
  const compareFn = (a: any, b: any) => {
    if (a[sortField] < b[sortField]) return -1;

    if (a[sortField] > b[sortField]) return 1;

    return 0;
  };

  return objList.sort(compareFn);
};

const underscore = (s: string) => {
  return s
    .split(/\.?(?=[A-Z])/)
    .join("_")
    .toLowerCase();
};

export { debounce, mapBy, objectUnion, sortBy, underscore, union, uniq };
