import VandalModel from "@/models/VandalModel";
import { connectToStore } from "kita";
import type TagStatsModel from "./TagStatsModel";

@connectToStore
export default class UserModel extends VandalModel {
  static readonly id = "users";
  declare readonly email: string;
  declare readonly username: string;
  declare readonly dateJoined: string;
  declare tagStats: TagStatsModel;

  public get isMattMitchOrOwen() {
    return ["matt", "mitch", "owen"].includes(this.username);
  }

  toString() {
    return this.email;
  }
}
