import PlaceModel from "@/models/PlaceModel";
import TagModel from "@/models/TagModel";
import VandalModel from "@/models/VandalModel";
import { connectToStore } from "kita";

interface Activity {
  tag: TagModel;
  place: PlaceModel;
  placeId: string;
  placetagId: string;
  type: ActivityType;
}

interface ActivityType {
  name: "vote_up" | "vote_down" | "vote_none | placetag_created";
}

@connectToStore
export default class NotificationModel extends VandalModel {
  static readonly id = "notifications";

  declare actorId: string;
  declare observerId: string;
  declare status: string;
  declare activityId: string;
  declare activity: Activity; // TODO: create activity store, model, etc

  static READ_STATUS = "read";
  static UNREAD_STATUS = "unread";
  static ARCHIVED_STATUS = "archived";
}
