import client from "@/services/api/client";
import * as Stores from "@/stores/index";
import { ApplicationStore, createAndRegisterStore } from "kita";

class VandalStore extends ApplicationStore {
  declare readonly places: Stores.PlaceStore;
  declare readonly tags: Stores.TagStore;
  declare readonly placeTags: Stores.PlaceTagStore;
  declare readonly users: Stores.UserStore;
  declare readonly notifications: Stores.NotificationStore;
  declare readonly locations: Stores.LocationStore;
  declare readonly notificationDevices: Stores.NotificationDeviceStore;
}

const { appStore, useStore } = createAndRegisterStore(VandalStore, Object.values(Stores), client);

export default appStore;

export { useStore };

export type { VandalStore };
