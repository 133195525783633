import navigator from "@/services/locationService";
import { Store, reactive } from "kita";

const { coords, locatedAt, error, permission, isGranted, isDenied, fetchCurrentLocation } =
  navigator;

type ManualLocation = {
  latitude: string;
  longitude: string;
  label: string | undefined;
};

class LocationStore extends Store<any> {
  static readonly id = "locations";

  @reactive({})
  declare manualLocation: ManualLocation;

  get currentLocation() {
    if (this.manualLocation?.longitude && this.manualLocation?.latitude) {
      return this.manualLocation;
    } else {
      return this.currentGPSLocation;
    }
  }

  get currentGPSLocation() {
    return coords.value;
  }

  get locatedAt() {
    return locatedAt;
  }

  get gpsError() {
    return error;
  }

  get permission() {
    return permission;
  }

  get isDenied() {
    return isDenied;
  }

  get isGranted() {
    return isGranted;
  }

  fetchCurrentLocation() {
    fetchCurrentLocation();
  }

  resetManualLocation() {
    this.manualLocation = {} as ManualLocation;
  }
}

export default LocationStore;
